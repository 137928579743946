'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.filters:DefaultFilter

 # @description

###
angular
  .module 'lpaDispatching'
  .filter 'default', [
    () ->
      return (input, defaultValue) ->
        if not input
          return defaultValue

        return input
  ]
